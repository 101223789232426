import { useQuery } from 'react-query'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import useAxios from '../../../hooks/useAxios'
import { AxiosError } from 'axios'
import { getCritiqueQueryParams } from '../useCritiqueCopy'

const PLACEHOLDER_THUMBNAIL =
  'https://images.ctfassets.net/7thvzrs93dvf/BRolUUzfdqGcjni6Wcd4T/f159048bb102f590bd7a9c2ce50a09dc/resume-example-second.png'

export type Critique = {
  id: number
  resume_id: number
  critiquer: {
    critiquer_first_name: string
    critiquer_last_name: string
    critiquer_email: string
    critique_image: string
    critiquer_signature: string
  }
  full_sections: Array<{
    sections: string
    Bodies: { voice_body: string }[]
    titles?: string
  }>
  full_summary: {
    headings: string
    full_bullet_points: { voice_body: string }[]
  }
  ats_data: {
    full_name: string
    most_recent_employer: string
    most_recent_professional_position: string
    phone_number: string
    email: string
    city: string
    region: string
    experience_years: string
    most_recent_school: string
    management_score: string
    industries: {
      name: string
      percent_of_overall: string
      percent_of_parent: string
      child_industries: {
        name: string
        percent_of_overall: string
        percent_of_parent: string
      }[]
    }
    competencies: { name: string; months: number }[]
    grouped_competencies: {
      '1': { name: string; months: number }[]
      '2': { name: string; months: number }[]
      '3': { name: string; months: number }[]
      '4': { name: string; months: number }[]
    }
    overall_description: string
    page_count: number
    resume_file_size: number
    resume_size_percentile: number
  }
  critique_type_id: number
  critique_profile_id: number
  is_expired: boolean
  expires_at: string
  emailed_at: string
  viewed_at: string
  created_at: string
  critique_url: string
}

export type useCritiqueArgs = {
  at?: string | null
  cs?: string | null
  pageReady?: boolean
}

export function useCritique(args: useCritiqueArgs) {
  const axios = useAxios()
  const { t } = useTranslation()

  const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined'

  const referrer = isBrowser ? document.referrer : ''
  const locationHref = isBrowser ? window.location.href : ''

  const getUtmParameters = () => {
    if (!isBrowser) return {}
    const params = new URLSearchParams(window.location.search)
    const utmParams: { [key: string]: string } = {}
    params.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = value
      }
    })
    return utmParams
  }

  return useQuery<Critique>({
    queryKey: ['critique', args.at, args.cs],
    queryFn: async () => {
      const fnParams = args.at || args.cs ? { at: args.at, cs: args.cs } : {}
      const utmParams = getUtmParameters()

      // Merge UTM params with existing query params
      const params = { ...fnParams, ...utmParams }

      const headers = {
        'X-Referrer': referrer,
        'X-Location': locationHref,
      }

      const { debug } = getCritiqueQueryParams()

      const { data, status } = await axios.get('/api/v2/leads/critique/full', {
        params, // Send UTMs as query parameters
        headers, // Send referrer and location as headers
      })

      if (status === 204 && !debug) {
        await navigate(t('slugs.accountHistory'))
      }

      return data
    },
    enabled: args.pageReady,
    retry: (failureCount, error) => {
      return failureCount < 3 && (error as AxiosError)?.response?.status !== 204
    },
    onError: async (error) => {
      const { debug } = getCritiqueQueryParams()
      if (debug) return

      if ((error as AxiosError)?.response?.status === 204) {
        await navigate(t('slugs.accountHistory'))
      }
    },
  })
}

export function useCritiqueThumbnail(activeCritique: Critique | undefined) {
  const axios = useAxios()

  return useQuery<string>({
    queryKey: ['critique-thumbnail', activeCritique],
    queryFn: async () => {
      if (typeof window === 'undefined') return PLACEHOLDER_THUMBNAIL
      const currentURL = window?.location?.hostname

      const critiqueURL = activeCritique?.critique_url
      const critiqueQueryParams = new URLSearchParams(critiqueURL)

      const isLocalhost = currentURL.includes('localhost')
      const pureDomain = currentURL.replace('staging.', '')

      const cs = critiqueQueryParams.get('cs')

      if (cs) {
        const { data } = await axios.get(
          `${
            isLocalhost ? '' : 'https://' + pureDomain
          }/api/ra/images/${cs}/1/256.png`,
          { responseType: 'blob' }
        )
        return URL.createObjectURL(data)
      } else {
        return PLACEHOLDER_THUMBNAIL
      }
    },
    enabled: Boolean(activeCritique?.critique_url),
    retry: (failureCount, error) => {
      return failureCount < 3 && (error as AxiosError)?.response?.status !== 404
    },
    onError: async () => {
      return PLACEHOLDER_THUMBNAIL
    },
  })
}
